import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrl } from "../axios";
import Cookies from "universal-cookie";
import saveAs from "file-saver";

const initialState = {
  currentClient: {},
  isLoading: false,
  debt: [],
  downloadCSV: [],
  userTimezone: "",
};
const cookies = new Cookies();

export const createClient = createAsyncThunk(
  "client/createClient",
  async (client, thunkAPI) => {
    try {
      const resp = await baseUrl.post("client/sign-up/", client, thunkAPI);
      cookies.set("token", resp.data.token, { path: "/" });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginClient = createAsyncThunk(
  "client/loginClient",
  async (client, thunkAPI) => {
    try {
      const resp = await baseUrl.put("client/sign-in/", client, thunkAPI);
      if (resp.data) {
        cookies.set("token", resp.data.token, { path: "/" });
      }
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logoutClient = createAsyncThunk(
  "_/logoutClient",
  async (client, thunkAPI) => {
    try {
      const resp = await baseUrl.put("/client/logout/", thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDebt = createAsyncThunk("debt/client", async (thunkAPI) => {
  try {
    const resp = await baseUrl.get("client/bill/debt/", thunkAPI);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCSV = createAsyncThunk("csv/client", async (thunkAPI) => {
  try {
    const resp = await baseUrl.get(
      "client/bill/csv/",
      { responseType: "blob" },
      thunkAPI
    );

    if (resp.status === 200) {
      let mimeType = resp.headers["content-type"];
      const name = resp.headers["content-disposition"].replace(
        "attachment;filename=",
        ""
      );
      const blob = new Blob([resp.data], { type: mimeType });
      saveAs(blob, name);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserTimezone = createAsyncThunk(
  "client/getUserTimezone",
  async (_, thunkAPI) => {
    try {
      // Use the browser's `Intl` API to get the user's timezone
      const offsetMinutes = new Date().getTimezoneOffset();
      const offsetHours = Math.abs(offsetMinutes) / 60;
      const gmtOffset = `GMT${offsetMinutes < 0 ? "+" : "-"}${offsetHours}`;

      return gmtOffset;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: {
    [createClient.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentClient = payload;
      toast.success("Successfully created account!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [createClient.pending]: (state) => {
      state.isLoading = true;
    },
    [createClient.rejected]: (state, { payload }) => {
      state.isLoading = true;
      toast.error(payload.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [loginClient.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentClient = payload.data;
      toast.success("Successfully logged in!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [loginClient.pending]: (state) => {
      state.isLoading = true;
    },
    [loginClient.rejected]: (state, { payload }) => {
      state.isLoading = true;
      toast.error(payload.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [logoutClient.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentClient = {};
      toast.success("Successfully logged out!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [logoutClient.pending]: (state) => {
      state.isLoading = true;
    },
    [logoutClient.rejected]: (state, { payload }) => {
      state.isLoading = true;
      toast.error(payload.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getDebt.pending]: (state) => {
      state.isLoading = true;
    },
    [getDebt.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.debt = payload;
    },
    [getDebt.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getCSV.pending]: (state) => {
      state.isLoading = true;
    },
    [getCSV.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getCSV.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getUserTimezone.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserTimezone.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.userTimezone = payload;
    },
    [getUserTimezone.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error("Failed to retrieve user's timezone", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});

export default clientSlice.reducer;
