import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../axios";
import { toast } from "react-toastify";

const initialState = {
  tenantBooking: [],
  isLoading: false,
  tenantPendingBooking: [],
};
export const getTenantPendingBooking = createAsyncThunk(
  "pending/landlord",
  async (thunkAPI) => {
    try {
      const resp = await baseUrl.get("booking/pending/tenant/", thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getTenantBooking = createAsyncThunk(
  "space/tenantBooking",
  async (thunkAPI) => {
    try {
      const resp = await baseUrl.get("booking/history/tenant/");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBookingStatusTenant = createAsyncThunk(
  "space/cancelTenant",
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.patch(
        "/booking/status/",
        null,
        { params },
        thunkAPI
      );
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  extraReducers: {
    [getTenantBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [getTenantBooking.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.tenantBooking = payload;
    },
    [getTenantBooking.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getTenantPendingBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [getTenantPendingBooking.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.tenantPendingBooking = payload;
    },
    [getTenantPendingBooking.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateBookingStatusTenant.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBookingStatusTenant.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      console.log(payload);
      if (payload.config.params.is_completed === false) {
        toast.success("Booking canceled successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Booking confirmed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      state.tenantPendingBooking = state.tenantPendingBooking.filter(
        (booking) => booking.id !== payload.config.params.id
      );
    },
    [updateBookingStatusTenant.rejected]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.response.data.detail === "Forbidden") {
        toast.error("Unable to cancel booking", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  },
});

export default tenantSlice.reducer;
