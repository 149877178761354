import React, { useState } from "react";
import "./my-spaces.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function Myspaces({ space, userTimezone }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const dateFrom = new Date(space.datetime_from);
  const dateTo = new Date(space.datetime_to);

  // Convert the user's time zone offset to minutes
  const userTimezoneOffsetMinutes = userTimezone.includes("+")
    ? 1
    : -1 * parseInt(userTimezone.slice(4));

  const adjustedDateFrom = new Date(dateFrom);
  adjustedDateFrom.setUTCHours(
    dateFrom.getUTCHours() + userTimezoneOffsetMinutes
  );

  const adjustedDateTo = new Date(dateTo);
  adjustedDateTo.setUTCHours(dateTo.getUTCHours() + userTimezoneOffsetMinutes);

  const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDateFrom = new Intl.DateTimeFormat(
    "en-UK",
    dateOptions
  ).format(adjustedDateFrom);
  const formattedDateTo = new Intl.DateTimeFormat("en-UK", dateOptions).format(
    adjustedDateTo
  );

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = () => {
    navigate("/space/" + space.space.id);
  };

  return (
    <div className="space-landlord-main-div">
      <div style={{ marginTop: "10px" }}>
        <span className="space-landlord-name-span" onClick={handleNavigate}>
          {space.space.name}
        </span>
      </div>
      <div className="space-landlord-top-div">
        <span className="space-landlord-time-span">Booking time</span>
        <span className="space-landlord-address-span">Address</span>
        <span className="space-landlord-status">Status</span>
        <span className="space-landlord-total-price-span">Total Price</span>
      </div>
      <div className="space-landlord-bot-div">
        <div className="space-landlord-bot-wrapper">
          <span className="space-landlord-from-span">from</span>
          <span className="space-landlord-to-span">to</span>
        </div>
        <div className="space-landlord-bot-wrapper2">
          <span className="space-landlord-date-from-span">
            {formattedDateFrom}
          </span>
          <span className="space-landlord-date-to-span">{formattedDateTo}</span>
        </div>

        <div className="space-landlord-bot-wrapper3">
          <span className="space-landlord-street-span">
            {space.space.address} {space.space.city}
          </span>
        </div>
        <span className="space-landlord-status-span">{space.status}</span>
        <span className="space-landlord-price-span">{space.cost}$</span>
        <button onClick={handleOpen} className="space-landlord-contacts-btn">
          Contact
          {isOpen ? (
            <FiChevronUp size={25} className="booking-icon-up" />
          ) : (
            <FiChevronDown size={25} className="booking-icon-down" />
          )}
        </button>
      </div>
      {isOpen ? (
        <div className="space-landlord-hidden-top-div">
          <span className="space-landlord-hidden-name">Name</span>
          <span className="space-landlord-hidden-phone">Phone number</span>
          <span className="space-landlord-hidden-contact">Contact</span>
        </div>
      ) : null}
      {isOpen ? (
        <div className="space-landlord-hidden-bot-div">
          <span className="space-landlord-hidden-name-span">
            {space.contact.first_name} {space.contact.last_name}
          </span>
          <span className="space-landlord-hidden-phone-span">
            {space.contact.phone_number}
          </span>
          <span className="space-landlord-hidden-contact-span">
            {space.contact.email}
          </span>
        </div>
      ) : null}
    </div>
  );
}
