import { useEffect } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useToken = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies.get("token");
  useEffect(() => {
    const redirect = async () => {
      if (token === "expired") {
        await navigate("/");
        cookies.remove("token", { path: "/" });
        toast.warn("Your session has expired please login again!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    redirect();
  }, [token]);
};

export default useToken;
