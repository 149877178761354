import React, { useEffect, useState } from "react";
import "./pendingBookingTenant.css";
import Topbar from "../../components/topbar/Topbar";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTenantPendingBooking } from "../../features/backendRoutes/tenantSlice";
import SpacePBT from "../../components/spacePBT/SpacePBT";
import PendingBookingsMobile from "../../components/pendingBookingsMobile/PendingBookingsMobile";

export default function PendingBookingLandlord() {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tenantPendingBooking, isLoading } = useSelector(
    (state) => state.tenant
  );
  const { userTimezone } = useSelector((state) => state.client);

  function handleClick() {
    navigate(-1);
  }
  useEffect(() => {
    dispatch(getTenantPendingBooking());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main-pbt-div">
      <Topbar status={"hide"} />
      <div className="header-pbt-div">
        <IoIosArrowBack className="pbt-back-icon" onClick={handleClick} />
        <span className="pbt-back-span" onClick={handleClick}>
          Back
        </span>
      </div>
      <h2 className="pbt-h2">Pending bookings</h2>
      <div className="pbt-wrapper-div">
        {isLoading ? (
          <p>Loading...</p>
        ) : tenantPendingBooking.length === 0 ? (
          <h3 className="pbt-no-spaces">You have no pending reservations</h3>
        ) : width > 920 ? (
          tenantPendingBooking.map((space) => (
            <SpacePBT
              space={space}
              key={space.id}
              userTimezone={userTimezone}
            />
          ))
        ) : (
          tenantPendingBooking.map((space) => (
            <PendingBookingsMobile
              space={space}
              key={space.id}
              cancelOption={"tenant"}
              userTimezone={userTimezone}
            />
          ))
        )}
      </div>
    </div>
  );
}
