import React, { useState, useRef, useMemo, useEffect } from "react";
import "./addressPage.css";
import { IoIosArrowBack } from "react-icons/io";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "../../assets/img/map_pin.png";
import L from "leaflet";

export default function AddressPage({ formData, setFormData, setShowPage }) {
  const LatLong = [44.804, 20.4651];
  const [position, setPosition] = useState(LatLong);
  const [pinError, setPinError] = useState(false);

  function handleClick() {
    setShowPage("SpaceInfoPage");
  }
  const DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      city: "",
      address: "",
      area: "",
      lat: position[0],
      lng: position[1],
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Required"),
      city: Yup.string()
        .matches(/^[a-zA-Z ]+$/, "City name should contain only letters")
        .max(50, "You can't enter more than 50 characters")
        .required("Required"),
      address: Yup.string()
        .matches(
          /^[a-zA-Z\s]+\d+$/,
          "Address should be in the format 'StreetName 123'"
        )
        .required("Required"),
      area: Yup.number()
        .typeError("Please enter only numbers")
        .required("Area is required"),
      lat: Yup.number()
        .required("Please pin your space on the map!")
        .test("is-empty", "Please pin your space on the map!", (value) => {
          return value !== undefined && value !== null && value !== "";
        }),
      lng: Yup.number(),
    }),

    onSubmit: (values) => {
      const latString = values.lat.toString();
      const lngString = values.lng.toString();

      // Update the form data with the stringified lat and lng
      setFormData({
        ...formData,
        ...values,
        lat: latString,
        lng: lngString,
      });
      setShowPage("ReservationInfoPage");
    },
  });

  useEffect(() => {
    formik.setFieldValue("lat", position.lat);
    formik.setFieldValue("lng", position.lng);
  }, [position]);

  const handleSubmit = () => {
    if (position[0] === 44.804 && position[1] === 20.4651) {
      setPinError(true);
    } else {
      formik.handleSubmit();
    }
  };

  function DraggableMarker() {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={DefaultIcon}
      >
        <Popup minWidth={90} offset={[0, -30]}>
          <span>Move market to your space location!</span>
        </Popup>
      </Marker>
    );
  }

  return (
    <div className="AddressPage-main-div">
      <form action="">
        <div className="AddressPage-top-div">
          <IoIosArrowBack
            className="AddressPage-back-icon"
            onClick={handleClick}
          />
          <span className="AddressPage-back-span" onClick={handleClick}>
            Back
          </span>
        </div>
        <h2 className="AddressPage-h2">Space location</h2>
        <div className="AddressPage-center-div">
          <label className="AddressPage-label">Country</label>
          <input
            type="text"
            name="country"
            onBlur={formik.handleBlur}
            value={formik.values.country}
            onChange={formik.handleChange}
            className="AddressPage-input"
            placeholder="Serbia"
          />
          {formik.touched.country && formik.errors.country ? (
            <p className="p-error">{formik.errors.country}</p>
          ) : null}
          <label className="AddressPage-label">City</label>
          <input
            type="text"
            name="city"
            onBlur={formik.handleBlur}
            value={formik.values.city}
            onChange={formik.handleChange}
            className="AddressPage-input"
            placeholder="Belgrade"
          />
          {formik.touched.city && formik.errors.city ? (
            <p className="p-error">{formik.errors.city}</p>
          ) : null}
          <label className="AddressPage-label">Address</label>
          <input
            type="text"
            name="address"
            onBlur={formik.handleBlur}
            value={formik.values.address}
            onChange={formik.handleChange}
            className="AddressPage-input"
            placeholder="Gavrila Principa 60"
          />
          {formik.touched.address && formik.errors.address ? (
            <p className="p-error">{formik.errors.address}</p>
          ) : null}
          <label className="AddressPage-label">Area² (sq. meters)</label>
          <input
            type="text"
            name="area"
            onBlur={formik.handleBlur}
            value={formik.values.area}
            onChange={formik.handleChange}
            className="AddressPage-input"
            placeholder="54"
          />
          {formik.touched.area && formik.errors.area ? (
            <p className="p-error">{formik.errors.area}</p>
          ) : null}
        </div>

        <div className="AddressPage-bot-div">
          <label className="map-label">
            Pin your space on the map by dragging the pin!
          </label>
          <MapContainer
            className="leaflet-map-address"
            center={LatLong}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker name="lat" />
          </MapContainer>
          {pinError ? (
            <p style={{ marginTop: "50px" }} className="p-error">
              {formik.errors.lat}
            </p>
          ) : null}
          <button
            type="button"
            className="AddressPage-btn"
            onClick={handleSubmit}
          >
            NEXT STEP
          </button>
        </div>
      </form>
    </div>
  );
}
