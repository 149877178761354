import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrl, imgUrl } from "../axios";

const initialState = {
  singleSpace: null,
  allSpaces: {},
  isLoading: false,
  spaces: [],
  filters: {},
  uploadImg: [],
};

export const getSpaces = createAsyncThunk(
  "spaces/getSpaces",
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.get("space/", { params });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFilters = createAsyncThunk(
  "space/getFilters",
  async (_, thunkAPI) => {
    try {
      const resp = await baseUrl.get("space/filter/");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addSpace = createAsyncThunk(
  "space/addSpace",
  async (space, thunkAPI) => {
    try {
      const resp = await baseUrl.post("space/", space, thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "space/uploadImage",
  async (file, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await imgUrl.post("space/image/", formData);
      const uploadedImage = resp.data;

      return uploadedImage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "image/deleteImage",
  async (params, thunkAPI) => {
    try {
      await baseUrl.delete("space/image/", { params }, thunkAPI);
      return params.file_url;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSpacesById = createAsyncThunk(
  "space/getSpace",
  async (id, thunkAPI) => {
    try {
      const resp = await baseUrl.get("space/", { params: { id } });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const spaceSlice = createSlice({
  name: "space",
  initialState,
  reducers: {
    clearImages: (state) => {
      state.uploadImg = [];
    },
  },
  extraReducers: {
    [getSpaces.pending]: (state) => {
      state.isLoading = true;
    },
    [getSpaces.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.spaces = payload.data;
    },
    [getSpaces.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [addSpace.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.spaces = [...state.spaces, payload];

      toast.success("Space added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [addSpace.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getSpacesById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.singleSpace = payload[0];
    },
    [getSpacesById.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getSpacesById.pending]: (state) => {
      state.isLoading = true;
    },
    [getFilters.pending]: (state) => {
      state.isLoading = true;
    },
    [getFilters.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.filters = payload;
    },
    [getFilters.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [uploadImage.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadImage.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.uploadImg = [...state.uploadImg, payload.file_url];

      toast.success("Image uploaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [uploadImage.rejected]: (state, { payload }) => {
      state.isLoading = false;

      toast.error("Image upload failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [deleteImage.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteImage.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success("Image deleted!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.uploadImg = state.uploadImg.filter((image) => image !== payload);
    },
    [deleteImage.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const { clearImages } = spaceSlice.actions;
export default spaceSlice.reducer;
