const countryPrefixes = [
  { country: "Serbia", prefix: "381", code: "RS" },
  { country: "Albania", prefix: "355", code: "AL" },
  { country: "BiH", prefix: "387", code: "BA" },
  { country: "Bulgaria", prefix: "359", code: "BG" },
  { country: "Croatia", prefix: "385", code: "HR" },
  { country: "Greece", prefix: "30", code: "GR" },
  { country: "Montenegro", prefix: "382", code: "ME" },
  { country: "North Macedonia", prefix: "389", code: "MK" },
  { country: "Romania", prefix: "40", code: "RO" },
  { country: "Slovenia", prefix: "386", code: "SI" },
];

export default countryPrefixes;
