import React, { useEffect, useState } from "react";
import "./pendingBookingLandlord.css";
import Topbar from "../../components/topbar/Topbar";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SpacePBL from "../../components/spacePBL/SpacePBL";
import { useDispatch, useSelector } from "react-redux";
import { getLandlordPendingBooking } from "../../features/backendRoutes/landlordSlice";
import PendingBookingsMobile from "../../components/pendingBookingsMobile/PendingBookingsMobile";

export default function PendingBookingLandlord() {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { landlordPendingBooking, isLoading } = useSelector(
    (state) => state.landlord
  );
  const { userTimezone } = useSelector((state) => state.client);

  function handleClick() {
    navigate(-1);
  }

  useEffect(() => {
    dispatch(getLandlordPendingBooking());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main-pbl-div">
      <Topbar status={"hide"} />
      <div className="header-pbl-div">
        <IoIosArrowBack className="pbl-back-icon" onClick={handleClick} />
        <span className="pbl-back-span" onClick={handleClick}>
          Back
        </span>
      </div>
      <h2 className="pbl-h2">Pending bookings</h2>
      <div className="pbl-wrapper-div">
        {isLoading ? (
          <p>Loading...</p>
        ) : landlordPendingBooking.length === 0 ? (
          <h3 className="pbl-no-spaces">You have no pending reservations</h3>
        ) : width > 920 ? (
          landlordPendingBooking.map((space) => (
            <SpacePBL
              space={space}
              key={space.id}
              userTimezone={userTimezone}
            />
          ))
        ) : (
          landlordPendingBooking.map((space) => (
            <PendingBookingsMobile
              space={space}
              key={space.id}
              cancelOption={"landlord"}
              completeBtn={true}
              userTimezone={userTimezone}
            />
          ))
        )}
      </div>
    </div>
  );
}
