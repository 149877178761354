import { useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

export const usePaginate = (link, params, setList) => {
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const fetchData = async () => {
      link &&
        axios
          .get(process.env.REACT_APP_API + link, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params,
          })
          .then((res) => {
            setList((prev) => {
              let copy = [...prev];
              copy = [...copy, ...res.data];
              return copy;
            });
          });
    };
    fetchData();
  }, [params]);
};

export default usePaginate;
