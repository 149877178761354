import React from "react";
import "./footer.css";
import icon from "../../assets/img/LinkedIn-Symbole 1.png";

export default function Footer() {
  const handleNavigate = () => {
    window.open("https://www.linkedin.com/company/sansoft-inn/", "_blank");
  };

  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-contact-div">
          <h4>Contacts</h4>
          <span>sansoft.inn@gmail.com</span>
          <img
            src={icon}
            alt=""
            className="linked-in-img"
            onClick={handleNavigate}
          />
        </div>
        <div className="footer-support-div">
          <h4>Support</h4>
          <span>sansoft.inn@gmail.com</span>
        </div>
      </div>
      <div className="footer-bottom-div">
        <h4>ⒸSanSoft.inn 2023</h4>
      </div>
    </div>
  );
}
