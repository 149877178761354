import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrl } from "../axios";

const initialState = {
  singleBooking: {},
  allBookings: [],
  isLoading: false,
  bookingAvailabilty: [],
  isChecked: false,
};

export const getBookingAvailabilty = createAsyncThunk(
  "booking/getBookingAvailabilty",
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.get("booking/availability/", { params });
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addBooking = createAsyncThunk(
  "booking/addBooking",

  async (booking, thunkAPI) => {
    try {
      const resp = await baseUrl.post("booking/", booking, thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clearAvailabilty: (state) => {
      state.bookingAvailabilty = [];
      state.isChecked = false;
    },
  },
  extraReducers: {
    [addBooking.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.allBookings = [...state.allBookings, payload];
    },
    [addBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [addBooking.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.response.data.detail[1].msg);
      toast.error(payload.response.data.detail);
    },
    [getBookingAvailabilty.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingAvailabilty.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.bookingAvailabilty = payload;
      state.isChecked = true;
    },
    [getBookingAvailabilty.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const { clearAvailabilty } = bookingSlice.actions;
export default bookingSlice.reducer;
