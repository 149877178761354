import React, { useState } from "react";
import "./bookingHistoryMobile.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function BookingHistoryMobile({ space, userTimezone }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const dateFrom = new Date(space.datetime_from);
  const dateTo = new Date(space.datetime_to);

  const userTimezoneOffsetMinutes = userTimezone.includes("+")
    ? 1
    : -1 * parseInt(userTimezone.slice(4));

  const adjustedDateFrom = new Date(dateFrom);
  adjustedDateFrom.setUTCHours(
    dateFrom.getUTCHours() + userTimezoneOffsetMinutes
  );

  const adjustedDateTo = new Date(dateTo);
  adjustedDateTo.setUTCHours(dateTo.getUTCHours() + userTimezoneOffsetMinutes);

  const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDateFrom = new Intl.DateTimeFormat(
    "en-UK",
    dateOptions
  ).format(adjustedDateFrom);
  const formattedDateTo = new Intl.DateTimeFormat("en-UK", dateOptions).format(
    adjustedDateTo
  );

  return (
    <div className={`bhm-main-div ${isOpen === true ? "add-class" : ""}`}>
      <div className="bhm-headers-div">
        <h3>{space.space.name}</h3>
      </div>
      <div className="bhm-booking-div">
        <div className="bhm-booking-wrapper">
          <h3>Booking Time</h3>
          <div>
            <span className="bhm-booking-from-span">from</span>
            <span className="bhm-booking-date-from-span">
              {formattedDateFrom}
            </span>
          </div>
          <div>
            <span>to</span>
            <span className="bhm-booking-date-to-span">{formattedDateTo}</span>
          </div>
        </div>
        <div className="bhm-address-wrapper">
          <h3 className="bhm-address-h">Address</h3>
          <span className="bhm-address-span">
            {space.space.address} {space.space.city}
          </span>
        </div>
      </div>
      <div className="bhm-address-div">
        <div className="bhm-status-div">
          <h3 className="bhm-status-h">Status</h3>
          <span className="bhm-space-status-span">{space.status}</span>
        </div>
        <div className="bhm-total-cost-div">
          <h3 className="bhm-total-cost-h">Total Cost</h3>
          <span className="bhm-total-cost-span">{space.cost}€</span>
        </div>
      </div>
      <div className="bhm-btn-container">
        <button onClick={handleOpen} className="bhm-btn">
          Contact
          {isOpen ? (
            <FiChevronUp size={25} className="booking-icon-up" />
          ) : (
            <FiChevronDown size={25} className="booking-icon-down" />
          )}
        </button>
      </div>
      {isOpen && (
        <div>
          <div className="bhm-contant-wrapper">
            <div className="bhm-name-div">
              <span className="bhm-name-span">Name</span>
              <span>
                {space.contact.first_name} {space.contact.last_name}
              </span>
            </div>
            <div className="bhm-contact-div">
              <span className="bhm-contact-span">Contact</span>
              <span>{space.contact.email}</span>
            </div>
          </div>
          <div className="bhm-phone-div">
            <span className="bhm-phone-span">Phone number</span>
            <span>+{space.contact.phone_number}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookingHistoryMobile;
