import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  landlordDropDown: false,
  tenantDropDown: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleLandlordDropdown: (state) => {
      state.landlordDropDown = !state.landlordDropDown;
    },
    toggleTenantDropdown: (state) => {
      state.tenantDropDown = !state.tenantDropDown;
    },
  },
});

export const { toggleLandlordDropdown, toggleTenantDropdown } =
  menuSlice.actions;

export default menuSlice.reducer;
