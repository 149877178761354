import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrl } from "../axios";

const initialState = {
  landlordSpaces: [],
  isLoading: false,
  landlordBookings: [],
  landlordSpacesHistory: [],
  landlordPendingBooking: [],
};

export const getLandlordPendingBooking = createAsyncThunk(
  "pending/landlord",
  async (thunkAPI) => {
    try {
      const resp = await baseUrl.get("booking/pending/landlord/", thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getLandlordSpace = createAsyncThunk(
  "space/landlord",
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.get("space/landlord/", params);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBookingHistoryLandlord = createAsyncThunk(
  "booking/landlord",
  async (thunkAPI) => {
    try {
      const resp = await baseUrl.get("booking/history/landlord/", thunkAPI);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteSpace = createAsyncThunk(
  "space/deleteSpace",
  async (params, thunkAPI) => {
    try {
      await baseUrl.delete("space/", { params }, thunkAPI);
      return params.id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBookingStatus = createAsyncThunk(
  "space/confirmSpace",
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.patch(
        "/booking/status/",
        null,
        { params },
        thunkAPI
      );
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const landlordSlice = createSlice({
  name: "space",
  initialState,
  extraReducers: {
    [getLandlordSpace.pending]: (state) => {
      state.isLoading = true;
    },
    [getLandlordSpace.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.landlordSpaces = payload;
    },
    [getLandlordSpace.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getLandlordPendingBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [getLandlordPendingBooking.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.landlordPendingBooking = payload;
    },
    [getLandlordPendingBooking.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getBookingHistoryLandlord.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingHistoryLandlord.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.landlordSpacesHistory = payload;
    },
    [getBookingHistoryLandlord.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [deleteSpace.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteSpace.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success("Space deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(state.landlordSpaces);
      state.landlordSpaces = state.landlordSpaces.filter(
        (spaces) => spaces.id !== payload
      );
    },
    [deleteSpace.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateBookingStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBookingStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.config.params.is_completed === false) {
        toast.success("Booking canceled successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Booking confirmed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      state.landlordPendingBooking = state.landlordPendingBooking.filter(
        (booking) => booking.id !== payload.config.params.id
      );
    },
    [updateBookingStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
      if (
        payload.response.data.detail ===
        "You can not complete booking before it started"
      ) {
        toast.error("You can not complete booking before it started", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (payload.response.data.detail === "Forbidden") {
        toast.error("Unable to cancel booking", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  },
});

export default landlordSlice.reducer;
