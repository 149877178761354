import React, { useEffect, useState } from "react";
import "./imagesPage.css";
import { IoIosArrowBack } from "react-icons/io";
import { HiPlus } from "react-icons/hi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addSpace } from "../../features/backendRoutes/spaceSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImage,
  deleteImage,
} from "../../features/backendRoutes/spaceSlice";
import { clearImages } from "../../features/backendRoutes/spaceSlice";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";

export default function ImagesPage({ formData, setFormData, setShowPage }) {
  const imageLinks = useSelector((state) => state.space.uploadImg);
  const loader = useSelector((state) => state.space.isLoading);
  const [uploadedImg, setUploadedImg] = useState(imageLinks);

  useEffect(() => {
    setUploadedImg(imageLinks);
  }, [imageLinks]);

  function handleClick() {
    setShowPage("ReservationInfoPage");
  }

  useEffect(() => {
    return () => dispatch(clearImages());
  }, []);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      uploaded_images: [],
    },
    validationSchema: Yup.object({
      uploaded_images: Yup.array(),
    }),
    onSubmit: async (values) => {
      setFormData({
        ...formData,
        ...values,
        image_urls: uploadedImg,
      });

      await dispatch(
        addSpace({
          ...formData,
          ...values,
          image_urls: uploadedImg,
        })
      );
      setShowPage("FinishPage");
    },
  });

  const handleAddImg = async (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      return;
    }

    // Check file type
    const fileType = file.type;
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (!allowedFileTypes.includes(fileType)) {
      toast.warn("Please upload a PNG, JPG, or JPEG file", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Check file size
    const fileSize = file.size; // in bytes
    const maxSize = 25 * 1024 * 1024; // 25 MB

    if (fileSize > maxSize) {
      toast.warn("File size must be less than 25 MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Check the number of uploaded images
    if (uploadedImg !== undefined && uploadedImg.length < 5) {
      // Dispatch the uploadImage action
      await dispatch(uploadImage(file));
    } else {
      toast.warn("You can't upload more than 5 images", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteImg = (fileUrl) => {
    dispatch(deleteImage({ file_url: fileUrl }));
  };

  return (
    <div className="ImagesPage-main-div">
      {loader ? (
        <div
          style={{
            minHeight: "90vh",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader color={"#6567AC"} size={150} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="ImagesPage-top-div">
            <IoIosArrowBack
              className="ImagesPage-back-icon"
              onClick={handleClick}
            />
            <span className="ImagesPage-back-span" onClick={handleClick}>
              Back
            </span>
          </div>
          <h2 className="ImagesPage-h2">Images</h2>
          <div className="ImagesPage-center-div">
            {imageLinks !== undefined && imageLinks.length > 0 ? (
              <div className="uploaded-images-div">
                {imageLinks.map((imageLink) => (
                  <div className="upload-img-container">
                    <img
                      className="uploaded-img"
                      key={imageLink}
                      src={imageLink}
                      alt={`Image ${imageLink + 1}`}
                    />
                    <IoMdClose
                      size={35}
                      className="delete-img-icon"
                      onClick={() => handleDeleteImg(imageLink)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="uploaded-images-div">
                <h2>Please upload you pictures!</h2>
              </div>
            )}
            <div className="ImagesPage-link-div">
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept="image/*"
                value={formik.values.uploadedImages}
                name="uploadedImages"
                onChange={handleAddImg}
              />
              <label htmlFor="fileInput" className="ImagesPage-label-addLink">
                Upload Images
              </label>
              <label htmlFor="fileInput" className="ImagesPage-addLink-icon">
                <HiPlus size={30} />
              </label>
            </div>
            <div className="ImagesPage-bot-div">
              <button className="ImagesPage-btn" type="submit">
                Finish
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
