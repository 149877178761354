import React, { useEffect, useState } from "react";
import "./my-space.css";
import Topbar from "../../components/topbar/Topbar";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Space from "../../components/space/Space";
import { useDispatch, useSelector } from "react-redux";
import { getLandlordSpace } from "../../features/backendRoutes/landlordSlice";
import MySpacesMobile from "../../components/MySpacesMobile/MySpacesMobile";
import { usePaginate } from "../../hooks/usePaginate";

export default function MySpaces() {
  const { landlordSpaces, isLoading } = useSelector((state) => state.landlord);
  const [width, setWidth] = useState(window.innerWidth);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandlordSpace());
  }, []);

  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 10;

    if (bottom) {
      setParams((prev) => {
        const copy = { ...prev };
        copy.offset = copy.offset + copy.limit;
        return copy;
      });
    }
  };

  usePaginate("/space/landlord/", params, setData);

  return (
    <div className="myspace-main-div">
      <Topbar status={"hide"} />
      <div className="myspace-header-div">
        <IoIosArrowBack className="myspace-back-icon" onClick={handleClick} />
        <span className="myspace-back-span" onClick={handleClick}>
          Back
        </span>
      </div>
      <h3 className="my-space-h3">My Spaces</h3>
      <div className="myspace-content-div" onScroll={handleScroll}>
        {isLoading ? (
          <p>Loading...</p>
        ) : landlordSpaces.length === 0 ? (
          <h3 className="pbl-no-spaces">You have no spaces</h3>
        ) : width > 940 ? (
          landlordSpaces.map((space) => <Space space={space} key={space.id} />)
        ) : (
          landlordSpaces.map((space) => (
            <MySpacesMobile space={space} key={space.id} />
          ))
        )}
      </div>
    </div>
  );
}
