import React, { useState } from "react";
import "./spacePBT.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBookingStatusTenant } from "../../features/backendRoutes/tenantSlice";

export default function SpacePBT({ space, userTimezone }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  //formating the dates
  const formatDate = (date, userTimezone) => {
    // Adjust the date to the user's time zone
    const adjustedDate = new Date(
      date.toLocaleString("en-US", { timeZone: userTimezone })
    );

    const day = adjustedDate.getDate();
    const month = adjustedDate.getMonth() + 1;
    const year = adjustedDate.getFullYear();
    const hours = adjustedDate.getHours();
    const minutes = adjustedDate.getMinutes();

    return `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year} ${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  };

  const datetimeFrom = new Date(space.datetime_from);
  const formattedDatetimeFrom = formatDate(datetimeFrom);

  const datetimeTo = new Date(space.datetime_to);
  const formattedDatetimeTo = formatDate(datetimeTo);

  //canceling booking
  const handleCancelBooking = () => {
    dispatch(updateBookingStatusTenant({ id: space.id, is_completed: false }));
  };

  const handleNavigate = () => {
    navigate("/space/" + space.space.id);
  };
  return (
    <div className="space-pbt-main-div">
      <div>
        <div className="space-pbt-name-div">
          <label className="space-pbt-name" onClick={handleNavigate}>
            {space.space.name}
          </label>
        </div>
        <div className="space-pbt-tittle-div">
          <span className="booking-pbt-span">Booking time</span>
          <span className="space-pbt-address-h">Address</span>
          <span className="space-pbt-total-price-span">Total Price</span>
        </div>
        <div className="space-pbt-wrapper-div">
          <div className="space-pbt-booking-div">
            <div className="booking-wrapper-pbt">
              <div className="booking-container-pbt">
                <span>from</span>
                <span>to</span>
              </div>
              <div>
                <span className="date-time-from-pbt">
                  {formattedDatetimeFrom}
                </span>
                <span className="date-time-to-pbt"> {formattedDatetimeTo}</span>
              </div>
            </div>
          </div>
          <div className="space-pbt-address-div">
            <span>
              {space.space.address} {space.space.city}
            </span>
          </div>
          <div className="space-pbt-price-div">
            <span className="space-pbt-price-span">{space.cost} €</span>
          </div>
        </div>
        {isOpen ? (
          <div className="space-pbt-contact-div">
            <div className="space-pbt-contact-name-div">
              <span className="space-pbt-contact-span">Name</span>
              <span>
                {space.contact.first_name} {space.contact.last_name}
              </span>
            </div>
            <div className="space-pbt-phone-div">
              <span className="space-pbt-contact-span">Phone number</span>
              <span>{space.contact.phone_number}</span>
            </div>
            <div className="space-pbt-email-div">
              <span className="space-pbt-contact-span">Contact</span>
              <span>{space.contact.email}</span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="space-pbt-button-div">
        <button
          className="space-pbt-cancel-btn"
          onClick={() => handleCancelBooking()}
        >
          Cancel
        </button>
        <button onClick={handleOpen} className="space-pbt-view-btn">
          Contact
          {isOpen ? (
            <FiChevronUp size={25} className="booking-icon-up" />
          ) : (
            <FiChevronDown size={25} className="booking-icon-down" />
          )}
        </button>
      </div>
    </div>
  );
}
