import React from "react";
import "./mySpacesMobile.css";
import { FaWindowClose } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteSpace } from "../../features/backendRoutes/landlordSlice";
import { useNavigate } from "react-router-dom";

const MySpacesMobile = ({ space }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteSpace = () => {
    dispatch(deleteSpace({ id: space.id }));
  };

  const handleNavigate = () => {
    navigate("/space/" + space.id);
  };
  return (
    <div className="space-mobile-main-container">
      <div className="space-mobile-header-container">
        <span className="space-mobile-name" onClick={handleNavigate}>
          {space.name}
        </span>
        <FaWindowClose
          onClick={handleDeleteSpace}
          className="space-mobile-delete-icon"
        />
      </div>
      <div className="space-mobile-wrapper">
        <div className="space-mobile-img-div">
          <img
            src={space.image_urls[0]}
            alt=""
            className="space-mobile-image"
            onClick={() => handleNavigate()}
          />
        </div>
        <div className="space-mobile-available-div">
          <h4>Available</h4>
          <div>
            <span>from {space.available_from}</span>
          </div>
          <div className="space-mobile-to-div">
            <span>to</span>
            <span className="space-mobile-available-to-span">
              {space.available_to}
            </span>
          </div>
        </div>
      </div>
      <div className="space-mobile-description-div">
        <h3 className="space-mobile-desc-h">Description</h3>
        <p className="space-mobile-desc-paragraph">{space.short_description}</p>
      </div>
      <div className="space-mobile-address-div">
        <h3 className="space-mobile-address-h">Address</h3>
        <span className="space-mobile-address-span">{space.address}</span>
      </div>
      <div className="space-mobile-cost-div">
        <span className="space-mobile-cost-span"> {space.price}€ per hour</span>
      </div>
    </div>
  );
};

export default MySpacesMobile;
