import { configureStore } from "@reduxjs/toolkit";
import spaceSlice from "./backendRoutes/spaceSlice";
import bookingSlice from "./backendRoutes/bookingSlice";
import clientSlice from "./backendRoutes/clientSlice";
import landlordSlice from "./backendRoutes/landlordSlice";
import tenantSlice from "./backendRoutes/tenantSlice";
import menuSlice from "./backendRoutes/menuSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["your/action/type"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp", "payload"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates", "payload"],
      },
    }),

  reducer: {
    space: spaceSlice,
    booking: bookingSlice,
    client: clientSlice,
    landlord: landlordSlice,
    tenant: tenantSlice,
    menu: menuSlice,
  },
});
