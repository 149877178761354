import React, { useState } from "react";
import "./reservationInfoPage.css";
import { IoIosArrowBack } from "react-icons/io";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ReservationInfoPage({
  formData,
  setFormData,
  setShowPage,
}) {
  const [commission, setCommission] = useState("");

  function handleClick() {
    setShowPage("AddressPage");
  }

  const formik = useFormik({
    initialValues: {
      price: "",
      available_from: "00:00",
      available_to: "00:00",
    },
    validationSchema: Yup.object({
      price: Yup.string()
        .required("Required")
        .matches(
          /^\d+(\.\d{1,2})?$/,
          "Please use numbers with an optional decimal point and up to two decimal places."
        ),
      available_from: Yup.string()
        .nullable()
        .required("Please enter correct time"),
      available_to: Yup.string()
        .nullable()
        .required("Please enter correct time")
        .test({
          name: "time-range",
          message: "Invalid time range",
          test: function (value) {
            const { available_from } = this.parent;
            if (!available_from || !value) {
              return true; // Skip validation if any of the fields are empty or null
            }
            const fromTime = parseInt(available_from.replace(":", ""), 10);
            const toTime = parseInt(value.replace(":", ""), 10);
            return fromTime <= toTime;
          },
        }),
    }),
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        available_from: values.available_from,
        available_to: values.available_to,
      };
      setFormData({ ...formData, ...formattedValues });
      setShowPage("ImagesPage");
    },
  });

  const handlePriceChange = (e) => {
    formik.handleChange(e);

    // Calculate and set the commission
    const price = e.target.value;
    if (price.trim() === "") {
      setCommission("");
    } else if (!isNaN(price)) {
      const commissionValue = (parseFloat(price) * 0.1).toFixed(2);
      setCommission(`${commissionValue} €`);
    } else {
      setCommission(""); // Clear the commission text if the input is not a valid number
    }
  };

  return (
    <div className="ReservationInfoPage-main-div">
      <form onSubmit={formik.handleSubmit}>
        <div className="ReservationInfoPage-top-div">
          <IoIosArrowBack
            className="ReservationInfoPage-back-icon"
            onClick={handleClick}
          />
          <span className="ReservationInfoPage-back-span" onClick={handleClick}>
            Back
          </span>
        </div>
        <h2 className="ReservationInfoPage-h2">Booking info</h2>
        <div className="ReservationInfoPage-center-div">
          <label className="ReservationInfoPage-label">
            Price for one hour
          </label>
          <input
            type="text"
            name="price"
            className="reservationPage-input"
            onBlur={formik.handleBlur}
            value={formik.values.price}
            onChange={handlePriceChange}
          />
          {formik.touched.price && formik.errors.price ? (
            <p className="p-error">{formik.errors.price}</p>
          ) : null}
        </div>
        <div className="ReservationInfoPage-bot-div">
          <div className="ReservationInfoPage-label-div">
            <label className="ReservationInfoPage-label-available">
              Available from
            </label>
            <label className="ReservationInfoPage-label-available">
              Available to
            </label>
          </div>

          <div className="ReservationInfoPage-input-div">
            <select
              className="time-picker-from"
              name="available_from"
              onBlur={formik.handleBlur}
              value={formik.values.available_from}
              onChange={formik.handleChange}
            >
              <optgroup
                label="00-24 Hours"
                className="time-picker-from-optgrop"
              >
                <option value="00:00">00:00</option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
              </optgroup>
            </select>

            <select
              className="time-picker-to"
              name="available_to"
              onBlur={formik.handleBlur}
              value={formik.values.available_to}
              onChange={formik.handleChange}
            >
              <optgroup label="00-24 Hours" className="time-picker-to-optgrop">
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                <option value="23:59">23:59</option>
              </optgroup>
            </select>
          </div>
          <div className="ResevationInfoPage-comission-wrapper">
            <span className="reservationPage-comission-text">
              * service commission 10%
            </span>
            {commission.length > 0 ? (
              <span className="reservationPage-comission-text-number">
                - {commission}
              </span>
            ) : null}
          </div>
          <div className="error-div">
            <div style={{ width: "45%", marginLeft: "2%" }}>
              {formik.touched.available_from && formik.errors.available_from ? (
                <p className="p-error">{formik.errors.available_from}</p>
              ) : null}
            </div>
            <div style={{ width: "45%" }}>
              {formik.touched.available_to && formik.errors.available_to ? (
                <p className="p-error">{formik.errors.available_to}</p>
              ) : null}
            </div>
          </div>
          <button className="ReservationInfoPage-btn" type="submit">
            NEXT STEP
          </button>
        </div>
      </form>
    </div>
  );
}
