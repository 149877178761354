import axios from "axios";
import Cookies from "universal-cookie";

export const axiosRequestInterceptor = async (config) => {
  const cookies = new Cookies();
  const token = await cookies.get("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const axiosResponseInterceptor = async (config) => {
  const cookies = new Cookies();
  const token = await cookies.get("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const imgUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const baseUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    "Content-Type": "application/json",
  },
});

baseUrl.interceptors.request.use(axiosRequestInterceptor, (e) =>
  Promise.reject(e)
);

baseUrl.interceptors.response.use(null, (resp) => {
  if (
    resp.response.status === 401 &&
    !resp.response.data.detail.includes("credentials")
  ) {
    const cookies = new Cookies();
    cookies.set("token", "expired", { path: "/" });
  }
  // FOR SOME REASON AXIOS DOES NOT RECOGNIZE THIS RESP AS ERROR and we need to tell him this is ERROR
  // FOR FUTURE DEV!!
  return Promise.reject(resp);
});

imgUrl.interceptors.request.use(axiosRequestInterceptor, (e) =>
  Promise.reject(e)
);

imgUrl.interceptors.response.use(null, (resp) => {
  if (
    resp.response.status === 401 &&
    !resp.response.data.detail.includes("credentials")
  ) {
    const cookies = new Cookies();
    cookies.set("token", "expired", { path: "/" });
  }
  // FOR SOME REASON AXIOS DOES NOT RECOGNIZE THIS RESP AS ERROR and we need to tell him this is ERROR
  // FOR FUTURE DEV!!
  return Promise.reject(resp);
});
