import React from "react";
import { ImCheckmark } from "react-icons/im";

const CheckBox = (props) => {
  const handleSquareClick = () => {
    props.setIsChecked(!props.isChecked);
  };

  const squareStyle = {
    width: "20px",
    height: "20px",
    border: "2px solid #6567AC ",
    borderRadius: "6px",
  };
  const checkMarkStyle = {
    width: "16px",
    height: "16px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };

  return (
    <div
      className="checkbox-square"
      style={squareStyle}
      onClick={handleSquareClick}
    >
      {props.isChecked ? (
        <ImCheckmark color="#AB3B61" style={checkMarkStyle} />
      ) : null}
    </div>
  );
};

export default CheckBox;
