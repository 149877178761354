import Home from "../pages/Home/Home";
import Host from "../pages/BecomeHost/Host";
import SignUp from "../pages/SignUp/SignUp";
import MySpaces from "../pages/MySpaces/MySpaces";
import MyBookings from "../pages/MyBookings/MyBookings";
import BookingsForMySpaces from "../pages/BookingsForMySpaces/BookingsForMySpaces";
import SpacePage from "../pages/Space/SpacePage";
import PendingBookingLandlord from "../pages/PendingBookingLandlord/PendingBookingLandlord";
import PendingBookingTenant from "../pages/PendingBookingTenant/PendingBookingTenant";
import DebtTable from "../pages/DebtPage/Debt";

export const onehourRoutes = [
  {
    path: "/",
    Component: <Home />,
  },
  {
    path: "/space/:id",
    Component: <SpacePage />,
  },
  {
    path: "/create_space",
    Component: <Host />,
  },
  {
    path: "/sign_up",
    Component: <SignUp />,
  },
  {
    path: "/my_space",
    Component: <MySpaces />,
  },
  {
    path: "/booking/tenant",
    Component: <MyBookings />,
  },
  {
    path: "/booking/history/landlord/",
    Component: <BookingsForMySpaces />,
  },
  {
    path: "/booking/pending/landlord",
    Component: <PendingBookingLandlord />,
  },
  {
    path: "/booking/pending/tenant",
    Component: <PendingBookingTenant />,
  },
  {
    path: "/debt",
    Component: <DebtTable />,
  },
];
