import React from "react";
import "./space.css";
import { FaWindowClose } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteSpace } from "../../features/backendRoutes/landlordSlice";
import { useNavigate } from "react-router-dom";

export default function Space({ space }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteSpace = () => {
    dispatch(deleteSpace({ id: space.id }));
  };

  const handleNavigate = () => {
    navigate("/space/" + space.id);
  };

  return (
    <div className="space-main-div">
      <div className="space-left-container">
        <h4 className="space-left-h4" onClick={() => handleNavigate()}>
          {space.name}
        </h4>
        <img
          src={space.image_urls[0]}
          alt=""
          className="space-image"
          onClick={() => handleNavigate()}
        />
      </div>
      <div className="space-right-container">
        <div className="right-container-header">
          <h4 className="space-right-h4">Description</h4>
          <FaWindowClose className="delete-icon" onClick={handleDeleteSpace} />
        </div>
        <p className="space-detail-desc">{space.short_description}</p>
        <span className="space-address-span">Address</span>
        <p className="space-paragraph-address">{space.address}</p>
        <span className="space-available-span">Available</span>
        <div className="right-container-bottom">
          <p className="right-container-bottom-paragraph">
            from {space.available_from} to {space.available_to}
          </p>
          <span className="right-container-price-span">
            €{space.price} per hour
          </span>
        </div>
      </div>
    </div>
  );
}
