import React, { useState } from "react";
import "./loginMobile.css";
import Topbar from "../../components/topbar/Topbar";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  createClient,
  loginClient,
} from "../../features/backendRoutes/clientSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import countryPrefixes from "../../assets/utils/constants";
import CheckBox from "../../components/checkbox/CheckBox";
import { FaWindowClose } from "react-icons/fa";

export default function LoginMobile() {
  const [selectedPrefix, setSelectedPrefix] = useState("381");
  const [isChecked, setIsChecked] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [page, setPage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick() {
    navigate(-1);
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      phone_number: Yup.string()
        .max(20, "Phone number must not exceed 20 characters")
        .test(
          "no-plus",
          "Phone number must not contain a plus sign",
          (value) => !value || !value.includes("+")
        )
        .test(
          "no-leading-zero",
          "Phone number must not start with zero",
          (value) => !value || value[0] !== "0"
        )
        .required("Phone number is required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password cannot be more than 20 characters")
        .matches(
          /^(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one uppercase letter and one number"
        )
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!isChecked) {
        return;
      }
      try {
        const phoneNumberWithPrefix = selectedPrefix + values.phone_number;
        const updatedValues = {
          ...values,
          phone_number: phoneNumberWithPrefix,
        };
        const resultAction = await dispatch(
          createClient(JSON.stringify(updatedValues, null, 2))
        );
        if (createClient.fulfilled.match(resultAction)) {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        throw error;
      }
      resetForm();
    },
  });

  const loginValidation = useFormik({
    initialValues: {
      phone_number: "",
      password: "",
    },
    validationSchema: Yup.object({
      phone_number: Yup.string()
        .max(20, "Phone number must not exceed 20 characters")
        .test(
          "no-plus",
          "Phone number must not contain a plus sign",
          (value) => !value || !value.includes("+")
        )
        .test(
          "no-leading-zero",
          "Phone number must not start with zero",
          (value) => !value || value[0] !== "0"
        )
        .required("Phone number is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password cannot be more than 20 characters")
        .matches(
          /^(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one uppercase letter and one number"
        )
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const phoneNumberWithPrefix = selectedPrefix + values.phone_number;
        const updatedValues = {
          ...values,
          phone_number: phoneNumberWithPrefix,
        };
        const resultAction = await dispatch(
          loginClient(JSON.stringify(updatedValues, null, 2))
        );
        if (loginClient.fulfilled.match(resultAction)) {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        throw error;
      }
      resetForm();
    },
  });

  const handlePrefixChange = (event) => {
    setSelectedPrefix(event.target.value);
  };

  const handleReadTermsOfUse = () => {
    setOpenTermsModal(!openTermsModal);
  };

  return (
    <>
      {openTermsModal ? (
        <div className="modal-term-of-use">
          <div
            className="modal-term-of-use-overlay"
            onClick={handleReadTermsOfUse}
          ></div>
          <div className="modal-term-of-use-content">
            <div className="terms-of-use-modal-header-div">
              <h3 className="terms-of-use-header">
                Terms of Use and Data Processing Agreement
              </h3>
              <FaWindowClose
                size={"35px"}
                className="close-term-of-use-modal-btn"
                onClick={handleReadTermsOfUse}
              />
            </div>
            <div className="terms-of-use-content-wrapper">
              <span className="terms-of-use-text">
                These Terms of Use and Data Processing Agreement (the
                "Agreement") are entered into by and between Sansoft and the
                user ("User") of the OneHour platform (the "Platform"). This
                Agreement sets forth the terms and conditions governing your use
                of the Platform and your consent for the processing of your
                personal data. By accessing and using the Platform, you agree to
                be bound by the terms of this Agreement.
              </span>
              <span className="terms-of-use-text">1. Use of the Platform:</span>
              <span className="terms-of-use-text">
                1.1. The Platform is provided to allow Users to access and
                utilize the services related to hourly commercial space rentals.
              </span>
              <span className="terms-of-use-text">
                1.2. Users are required to register an account, providing
                accurate and complete information, to access and use the
                Platform.
              </span>
              <span className="terms-of-use-text">
                1.3. Users are responsible for maintaining the confidentiality
                of their account credentials and for all activities associated
                with their account.
              </span>
              <span className="terms-of-use-text">
                2. Data Processing and Privacy
              </span>
              <span className="terms-of-use-text">
                2.1. The Company collects and processes personal data in
                accordance with GDPR.
              </span>
              <span className="terms-of-use-text">
                2.2. By using the Platform, Users consent to the collection,
                processing, and storage of their personal data. Such as email,
                phone number, name and lastname.
              </span>
              <span className="terms-of-use-text">3. User Obligations:</span>
              <span className="terms-of-use-text">
                3.1. Users agree not to use the Platform for any unlawful
                purposes or in violation of this Agreement.
              </span>
              <span className="terms-of-use-text">
                3.2. Users are responsible for the accuracy and legality of the
                information and content they provide on the Platform.
              </span>
              <span className="terms-of-use-text">4. Termination:</span>
              <span className="terms-of-use-text">
                4.1. The Company reserves the right to terminate or suspend a
                User's account, or deny access to the Platform, at its sole
                discretion.
              </span>
              <span className="terms-of-use-text">5. Disclaimers:</span>
              <span className="terms-of-use-text">
                5.1. The Company makes no representations or warranties
                regarding the accuracy, reliability, or availability of the
                Platform.
              </span>
              <span className="terms-of-use-text">
                6. Limitation of Liability:
              </span>
              <span className="terms-of-use-text">
                6.1. The Company shall not be liable for any indirect,
                consequential, or incidental damages.
              </span>
              <span className="terms-of-use-text">7. Contact Information:</span>
              <span className="terms-of-use-text">
                7.1. For questions or concerns related to this Agreement or the
                Platform, please contact via email sansoft.inn@gmail.com.
              </span>
              <span className="terms-of-use-text">
                By using the Platform, you acknowledge that you have read,
                understood, and agree to be bound by this Agreement. If you do
                not agree to these terms, you should not use the Platform.
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <Topbar status={"hide"} />
      <div className="login-mobile-container">
        <div className="back-button-div-mobile">
          <IoIosArrowBack className="signup-back-icon" onClick={handleClick} />
          <span className="signup-back-span" onClick={handleClick}>
            Back
          </span>
        </div>
        {page === true ? (
          <form
            action="none"
            className="login-mobile-wrapper"
            onSubmit={formik.handleSubmit}
          >
            <h3 className="sign-up-mobile-header">Create account</h3>
            <span className="sign-up-mobile-span-phone">Phone number</span>
            <div className="phone-number-input-container-mobile">
              <select
                className="phone-number-prefix-container-mobile"
                value={selectedPrefix}
                onChange={handlePrefixChange}
              >
                {countryPrefixes.map(({ country, prefix, code }) => (
                  <option
                    key={country}
                    label={`${prefix} (${code})`}
                    value={code}
                  />
                ))}
              </select>
              <input
                className="sign-up-mobile-phone-input"
                type="text"
                name="phone_number"
                onBlur={formik.handleBlur}
                value={formik.values.phone_number}
                onChange={formik.handleChange}
              />
            </div>
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <p className="paragraph-error">{formik.errors.phone_number}</p>
            ) : null}
            <span className="sign-up-mobile-span-name">Name</span>
            <input
              className="sign-up-mobile-name-input"
              type="text"
              name="first_name"
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              onChange={formik.handleChange}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <p className="paragraph-error">{formik.errors.first_name}</p>
            ) : null}
            <span className="sign-up-mobile-span-surename">Last name</span>
            <input
              className="sign-up-mobile-surename-input"
              type="text"
              name="last_name"
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
            {formik.touched.last_name && formik.errors.last_name ? (
              <p className="paragraph-error">{formik.errors.last_name}</p>
            ) : null}
            <span className="sign-up-mobile-span-email">Email</span>
            <input
              className="sign-up-mobile-email-input"
              type="email"
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="paragraph-error">{formik.errors.email}</p>
            ) : null}
            <span className="sign-up-mobile-span-password">Password</span>
            <input
              className="sign-up-mobile-password-input"
              type="password"
              name="password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="paragraph-error">{formik.errors.password}</p>
            ) : null}
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckBox isChecked={isChecked} setIsChecked={setIsChecked} />
              <span
                className="sign-up-terms-of-use-text"
                onClick={handleReadTermsOfUse}
              >
                I agree with terms of use
              </span>
            </div>
            <button
              style={{
                opacity: isChecked ? 1 : 0.7,
                cursor: isChecked ? "pointer" : "default",
              }}
              className="sign-up-mobile-btn"
              type="submit"
            >
              Register
            </button>
            <h4 className="mobile-sign-up-header4">Already registered?</h4>
            <span
              className="mobile-sign-up-span-text"
              onClick={() => setPage(false)}
            >
              Login to existing account
            </span>
          </form>
        ) : (
          <form
            action="none"
            onSubmit={loginValidation.handleSubmit}
            className="login-mobile-wrapper"
          >
            <h3 className="login-mobile-header">Login</h3>
            <span className="login-mobile-span-phone">Phone number</span>
            <div className="phone-number-input-container-mobile">
              <select
                className="phone-number-prefix-container-mobile"
                value={selectedPrefix}
                onChange={handlePrefixChange}
              >
                {countryPrefixes.map(({ country, prefix, code }) => (
                  <option
                    key={country}
                    label={`${prefix} (${code})`}
                    value={code}
                  />
                ))}
              </select>
              <input
                className="phone-number-login-mobile-input"
                type="text"
                name="phone_number"
                onBlur={loginValidation.handleBlur}
                value={loginValidation.values.phone_number}
                onChange={loginValidation.handleChange}
              />
            </div>
            {loginValidation.touched.phone_number &&
            loginValidation.errors.phone_number ? (
              <p className="paragraph-error">
                {loginValidation.errors.phone_number}
              </p>
            ) : null}
            <span className="login-mobile-span-password">Password</span>
            <input
              className="password-login-mobile-input"
              type="password"
              name="password"
              onBlur={loginValidation.handleBlur}
              value={loginValidation.values.password}
              onChange={loginValidation.handleChange}
            />
            {loginValidation.touched.password &&
            loginValidation.errors.password ? (
              <p className="paragraph-error">
                {loginValidation.errors.password}
              </p>
            ) : null}
            <button className="sign-in-mobile-btn" type="submit">
              Login
            </button>
            <h4 className="mobile-login-header4">First time here?</h4>
            <span
              className="mobile-login-span-text"
              onClick={() => setPage(true)}
            >
              Create account
            </span>
          </form>
        )}
      </div>
    </>
  );
}
