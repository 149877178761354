import React from "react";
import "./spaceInfoPage.css";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SpaceTypeSelector from "../SpaceTypeSelector/SpaceTypeSelector";

export default function SpaceInfoPage({ formData, setFormData, setShowPage }) {
  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "sports_area",
      short_description: "",
      detailed_description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      short_description: Yup.string()
        .max(60, "Short description cannot be longer than 60 characters")
        .required("Required"),
      detailed_description: Yup.string()
        .max(250, "Detailed description cannot be longer than 250 characters")
        .required("Required"),
    }),
    onSubmit: (values) => {
      setFormData(values);
      setShowPage("AddressPage");
    },
  });

  return (
    <div className="SpaceInfoPage-main-div">
      <form onSubmit={formik.handleSubmit}>
        <div className="SpaceInfoPage-top-div">
          <IoIosArrowBack
            className="SpaceInfoPage-back-icon"
            onClick={handleClick}
          />
          <span className="SpaceInfoPage-back-span" onClick={handleClick}>
            Back
          </span>
        </div>
        <h2 className="SpaceInfoPage-h2">Space and owner info</h2>
        <div className="SpaceInfoPage-center-div">
          <label className="SpaceInfoPage-label">Space type</label>
          <SpaceTypeSelector
            name="type"
            onChange={(value) => formik.setFieldValue("type", value)}
          />
          <label className="SpaceInfoPage-label">Space name</label>
          <input
            type="text"
            name="name"
            className="SpaceInfoPage-input"
            onBlur={formik.handleBlur}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="p-error">{formik.errors.name}</p>
          ) : null}

          <label className="SpaceInfoPage-label">Short description</label>
          <textarea
            type="text"
            name="short_description"
            className="SpaceInfoPage-textarea1"
            onBlur={formik.handleBlur}
            maxLength={60}
            value={formik.values.short_description}
            onChange={formik.handleChange}
          />
          {formik.touched.short_description &&
          formik.errors.short_description ? (
            <p className="p-error">{formik.errors.short_description}</p>
          ) : null}
          <label className="SpaceInfoPage-label">Detailed description</label>
          <textarea
            type="text"
            name="detailed_description"
            className="SpaceInfoPage-textarea2"
            onBlur={formik.handleBlur}
            maxLength={250}
            value={formik.values.detailed_description}
            onChange={formik.handleChange}
          />
          {formik.touched.detailed_description &&
          formik.errors.detailed_description ? (
            <p className="p-error">{formik.errors.detailed_description}</p>
          ) : null}

          <button className="SpaceInfoPage-btn" type="submit">
            NEXT STEP
          </button>
        </div>
      </form>
    </div>
  );
}
