import React from "react";
import Home from "./pages/Home/Home";
import SignUp from "./pages/SignUp/SignUp";
import { onehourRoutes } from "./routes/Routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import PrivateRoutes from "./PrivateRoute";
import SpacePage from "./pages/Space/SpacePage";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/space/:id" element={<SpacePage />} />
        <Route path="/sign_up" element={<SignUp />} />
        <Route element={<PrivateRoutes />}>
          {onehourRoutes.map((route, index) => (
            <Route key={index} element={route.Component} path={route.path} />
          ))}
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
