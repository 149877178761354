import React from "react";
import "./debt.css";
import Topbar from "../../components/topbar/Topbar";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function DebtTable() {
  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  return (
    <div className="debt-main-div">
      <Topbar status="hide" />
      <div className="debt-header">
        <IoIosArrowBack
          onClick={() => handleClick()}
          className="debt-back-button"
        />
        <span className="debt-back-span">Back</span>
        <h2 className="debt-h2">Debt</h2>
      </div>
      <div className="debt-container">
        <div className="div-container-header">
          <h4 className="debt-h4">Booking ID</h4>
          <h4 className="debt-h4">Space</h4>
          <h4 className="debt-h4">Country</h4>
          <h4 className="debt-h4">Address</h4>
          <h4 className="debt-h4">From</h4>
          <h4 className="debt-h4">To</h4>
          <h4 className="debt-h4">Cost</h4>
          <h4 className="debt-h4">Fee</h4>
          <h4 className="debt-h4">Currency</h4>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
        <div className="debt-row">
          <span className="debt-row-span">
            143a6fa4-6f3b-4e75-95fc-dea759942d61
          </span>
          <span className="debt-row-span">Fitness club "Reform"</span>
          <span className="debt-row-span">Russia</span>
          <span className="debt-row-span">
            Nedeljka Gvozdenovica, 29, Beograd
          </span>
          <span className="debt-row-span">10.10.2022 8:00</span>
          <span className="debt-row-span">10.10.2022 12:00</span>
          <span className="debt-row-span">800.00</span>
          <span className="debt-row-span">80.00</span>
          <span className="debt-row-span">RSD</span>
        </div>
      </div>
      <div className="debt-bottom-container">
        <h4 className="debt-fee-h4">Total fee: 500.00 RSD</h4>
        <button className="pay-btn">PAY</button>
      </div>
    </div>
  );
}
