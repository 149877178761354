import React, { useState } from "react";
import "./sideMenu.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getCSV, logoutClient } from "../../features/backendRoutes/clientSlice";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  toggleLandlordDropdown,
  toggleTenantDropdown,
} from "../../features/backendRoutes/menuSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsQuestionCircle } from "react-icons/bs";
import { FaWindowClose } from "react-icons/fa";

export default function SideMenu(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { landlordDropDown, tenantDropDown } = useSelector(
    (state) => state.menu
  );
  const [openQuestionModal, setOpenQuestionModal] = useState(false);

  const handleNavigateBookingHistoryTenant = () => {
    navigate("/booking/tenant");
  };
  const handleNavigateBookingHistoryLandlord = () => {
    navigate("/booking/history/landlord/");
  };
  const handleNavigateRent = () => {
    navigate("/create_space");
  };
  const handleNavigateMySpace = () => {
    navigate("/my_space");
  };
  const handleNavigatePendingBookingsLandlord = () => {
    navigate("/booking/pending/landlord");
  };
  const handleNavigatePendingBookingsTenant = () => {
    navigate("/booking/pending/tenant");
  };
  const handleLogout = async () => {
    await dispatch(logoutClient());
    cookies.remove("token", { path: "/" });
    props.setOpenMenu(false);
    navigate("/");
  };

  let cName = "side-menu-container";
  if (props.openMenu) {
    cName += " side-menu-acitve";
  }

  let cOverlay = "";
  if (props.openMenu) {
    cOverlay = "side-menu-overlay-active";
  }

  const handleDownloadCSV = () => {
    dispatch(getCSV());
  };

  const handleOpenQuestionMarkModal = () => {
    props.setOpenMenu(false);
    setOpenQuestionModal(!openQuestionModal);
  };

  return (
    <>
      {openQuestionModal && (
        <div className="modal-term-of-use">
          <div
            className="modal-term-of-use-overlay"
            onClick={handleOpenQuestionMarkModal}
          ></div>
          <div className="modal-term-of-use-content">
            <div className="terms-of-use-modal-header-div">
              <h3 className="terms-of-use-header">How to use OneHour?</h3>
              <FaWindowClose
                size={"35px"}
                className="close-term-of-use-modal-btn"
                onClick={handleOpenQuestionMarkModal}
              />
            </div>
            <div className="terms-of-use-content-wrapper">
              <span className="terms-of-use-text">
                Dear Landlord, We are pleased to welcome you to the OneHour
                platform and offer you a set of useful recommendations for using
                our platform.
              </span>
              <span className="terms-of-use-text">
                Create an Account on the OneHour Platform: To do this, follow
                these steps:
              </span>
              <span className="terms-of-use-text">
                Click on the app login button at the top of the screen. Select
                "Sign Up." Fill in your personal information; you will receive
                notifications about bookings via phone number and email. Create
                a Rental Property:
              </span>
              <span className="terms-of-use-text">
                Open the app menu by clicking on the icon at the top of the
                screen. Click on "Add Space." Fill in all the necessary
                information about your property, including its description,
                location, photos, and the hourly rental rate. Managing
                Properties and Bookings:
              </span>
              <span className="terms-of-use-text">
                You can manage your properties through the "My Spaces" menu:
                view and delete them. At present, changing property information
                is not available and can be done by creating a new property or
                contacting OneHour support.
              </span>
              <span className="terms-of-use-text">
                You can manage bookings through the "Pending Bookings" menu.
              </span>
              <span className="terms-of-use-text">
                If a booking is successfully completed and payment is made,
                press the "Complete" button. If a booking cannot be completed,
                press the "Cancel" button. You can view the history of completed
                bookings through the "Booking history" menu.
              </span>

              <span className="terms-of-use-text">
                Don't forget to check notifications about new bookings via SMS
                and email.
              </span>
              <span className="terms-of-use-text">
                Maintaining Cleanliness and Order: Provide a clean and
                well-maintained space for your customers. Inspect the property
                after each booking to ensure it is in excellent condition.
              </span>
              <span className="terms-of-use-text">
                Interacting with Customers: Be prepared to respond to customer
                inquiries and provide necessary information by sharing your
                contact details during registration or using the "Contact"
                button in the "Pending bookings" and "Booking history" sections.
              </span>
              <span className="terms-of-use-text">
                Rating and Feedback: Raise any platform-related issues or
                problems through our support service.
              </span>
              <span className="terms-of-use-text">
                Payment for Using the OneHour Platform: You can obtain a
                detailed invoice for using the OneHour platform in CSV format
                through the "Debt" menu. Payment can be made in person when
                meeting with a OneHour team representative or through prior
                agreement on non-cash transfers.
              </span>
              <span className="terms-of-use-text">
                Ensure that your profile is always updated and current.
              </span>

              <span className="terms-of-use-text">
                Thank you for choosing OneHour. We strive to make the rental of
                commercial properties more convenient and profitable. If you
                have any questions or need assistance, please do not hesitate to
                contact our support service.
              </span>
              <span className="terms-of-use-text">
                Best regards, The OneHour Team Alexander Safronov
                sansoft.inn@gmail.com +381637736147
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className={cOverlay}
        onClick={() => props.setOpenMenu(!props.openMenu)}
      ></div>
      <div className={cName}>
        <div className="side-menu-wrapper">
          <div
            style={{ marginRight: "15px" }}
            className="tenant-drop-down-wrapper"
            onClick={() => dispatch(toggleTenantDropdown())}
          >
            <span className="tenants-span">For tenants</span>
            {tenantDropDown ? (
              <FiChevronUp className="fi-icon-drop-down" size={25} />
            ) : (
              <FiChevronDown className="fi-icon-drop-down" size={25} />
            )}
          </div>
          {tenantDropDown ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight:
                  props.debt.tenant_pendings_count > 0 ? "0px" : "30px",
              }}
            >
              <span
                onClick={handleNavigateBookingHistoryTenant}
                className="side-menu-span"
              >
                Booking history
              </span>
              <div className="side-menu-tenant-pending-div">
                <span
                  onClick={handleNavigatePendingBookingsTenant}
                  className="side-menu-span"
                >
                  Pending bookings
                </span>
                {props.debt.tenant_pendings_count !== undefined &&
                  props.debt.tenant_pendings_count > 0 && (
                    <span className="side-menu-pending-bookings-circle">
                      {props.debt.tenant_pendings_count}
                    </span>
                  )}
              </div>
            </div>
          ) : null}

          <div
            className="tenant-drop-down-wrapper"
            style={{ marginLeft: "5px" }}
            onClick={() => dispatch(toggleLandlordDropdown())}
          >
            <span className="landlords-span">For landlords</span>
            {landlordDropDown ? (
              <FiChevronUp className="fi-icon-drop-down" size={25} />
            ) : (
              <FiChevronDown className="fi-icon-drop-down" size={25} />
            )}
          </div>
          {landlordDropDown ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight:
                  props.debt.landlord_pendings_count > 0 ? "0px" : "30px",
              }}
            >
              <span onClick={handleNavigateRent} className="side-menu-span">
                Add space
              </span>
              <span onClick={handleNavigateMySpace} className="side-menu-span">
                My spaces
              </span>
              <span
                onClick={handleNavigateBookingHistoryLandlord}
                className="side-menu-span"
              >
                Booking history
              </span>
              <div className="side-menu-pending-wrapper">
                <span
                  onClick={handleNavigatePendingBookingsLandlord}
                  className="side-menu-span"
                >
                  Pending bookings
                </span>
                {props.debt.landlord_pendings_count !== undefined &&
                  props.debt.landlord_pendings_count > 0 && (
                    <span className="side-menu-pending-bookings-circle">
                      {props.debt.landlord_pendings_count}
                    </span>
                  )}
              </div>
              <BsQuestionCircle
                onClick={handleOpenQuestionMarkModal}
                size={30}
                className="question-mark-side-menu"
              />
              <span
                className="side-menu-debt-span"
                onClick={() => handleDownloadCSV()}
              >
                Debt: €{props.debt.total_fee}
              </span>
            </div>
          ) : null}

          <span className="side-menu-logout-span" onClick={handleLogout}>
            Logout
          </span>
          <span className="side-menu-company-span">ⒸSanSoft.inn 2023</span>
        </div>
      </div>
    </>
  );
}
