import React, { useEffect, useState } from "react";
import Topbar from "../../components/topbar/Topbar";
import "./my-bookings.css";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import BookingTenant from "../../components/mybooking/BookingTenant";
import { useDispatch, useSelector } from "react-redux";
import { getTenantBooking } from "../../features/backendRoutes/tenantSlice";
import BookingHistoryMobile from "../../components/bookingHistoryMobile/BookingHistoryMobile";
import { usePaginate } from "../../hooks/usePaginate";

export default function MyBookings() {
  const [width, setWidth] = useState(window.innerWidth);
  const { isLoading } = useSelector((state) => state.tenant);
  const { userTimezone } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getTenantBooking());
  }, []);

  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 10;

    if (bottom) {
      setParams((prev) => {
        const copy = { ...prev };
        copy.offset = copy.offset + copy.limit;
        return copy;
      });
    }
  };

  usePaginate("/booking/history/tenant/", params, setData);

  let isDataEmpty = data.length < 1;

  return (
    <div className="my-bookings-main-div">
      <Topbar status={"hide"} />
      <div className="my-bookings-header-div">
        <IoIosArrowBack
          className="my-bookings-back-icon"
          onClick={handleClick}
        />
        <span className="my-bookings-back-span" onClick={handleClick}>
          Back
        </span>
      </div>
      <h2 className="my-bookings-h2">My Bookings</h2>
      <div
        onScroll={handleScroll}
        className={`my-bookings-wrapper ${
          isDataEmpty === true ? "hide-scrollbar" : ""
        }`}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : data.length === 0 ? (
          <h3 className="pbt-no-spaces">You have no booking history</h3>
        ) : width > 940 ? (
          data.map((space) => (
            <BookingTenant
              key={space.id}
              space={space}
              userTimezone={userTimezone}
            />
          ))
        ) : (
          data.map((space) => (
            <BookingHistoryMobile
              space={space}
              key={space.id + 1}
              userTimezone={userTimezone}
            />
          ))
        )}
      </div>
    </div>
  );
}
