import React from "react";
import "./spaceTypeSelector.css";

export default function SpaceTypeSelector({ name, onChange }) {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <select className="type-select" name={name} onChange={handleSelectChange}>
      <option value="sports_area" className="option-style">
        Sports Area
      </option>
      <option value="photo_studio" className="option-style">
        Photo Studio
      </option>
      <option value="conference_hall" className="option-style">
        Conference Hall
      </option>
      <option value="massage_room" className="option-style">
        Massage Room
      </option>
      <option value="music_hall" className="option-style">
        Music Hall
      </option>
    </select>
  );
}
