import React, { useState, useEffect } from "react";
import "./topbar.css";
import Logo from "../../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { getFilters } from "../../features/backendRoutes/spaceSlice";
import useToken from "../../hooks/useToken";
import { BiLogIn } from "react-icons/bi";
import FilterIcon from "../../assets/svg/filter.svg";
import { FaWindowClose } from "react-icons/fa";
import SideMenu from "../Menu/SideMenu";
import { getDebt } from "../../features/backendRoutes/clientSlice";
import { CgMenuBoxed } from "react-icons/cg";
import { getUserTimezone } from "../../features/backendRoutes/clientSlice";

export default function Topbar(props) {
  const [isElementHidden, setIsElementHidden] = useState(props.status);
  const [toggleFilterModal, setToggleFilterModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const navigate = useNavigate();
  const cookies = new Cookies();
  const hasCookie = cookies.get("token");
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.space);
  const { debt, isLoading, userTimezone } = useSelector(
    (state) => state.client
  );

  useToken();

  useEffect(() => {
    if (!hasCookie) {
      return;
    } else {
      dispatch(getUserTimezone());
    }
  }, [dispatch, userTimezone, hasCookie]);

  const handleSelect = (value, name) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleApplyFilter = () => {
    props.setData([]);
    const { city, type, available_from, available_to } = selectedFilters;
    props.setParams((prev) => ({
      ...prev,
      ...selectedFilters,
    }));
    setToggleFilterModal(false);
  };

  const handleResetFilters = () => {
    props.setData([]);
    props.setParams({ offset: 0, limit: 10, city: "" });
    setSelectedFilters({});
    setToggleFilterModal(false);
  };

  const handleSignIn = () => {
    navigate("/sign_up");
  };
  useEffect(() => {
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    if (openMenu === true && !hasCookie) {
      setOpenMenu(false);
    }
  }, [openMenu, hasCookie]);

  const formatName = (o) => {
    const formattedWord = o.replace(/_/g, " ");
    return formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1);
  };
  const formatData = (name, modifyName) => {
    if (filters[name]) {
      return filters[name].map((o) => ({
        value: o,
        label: modifyName ? formatName(o) : o,
      }));
    }
  };

  const toggleFilter = () => {
    setToggleFilterModal(!toggleFilterModal);
  };

  const handleOpenMenu = () => {
    if (hasCookie) {
      dispatch(getDebt());
    }
    setOpenMenu(!openMenu);
  };

  const renderContent =
    props.status === "hide" || props.status === "show" ? (
      !hasCookie ? (
        <BiLogIn
          className="topbar-sign-in-btn"
          onClick={handleSignIn}
          size={40}
        />
      ) : (
        <CgMenuBoxed className="menu-btn" onClick={handleOpenMenu} size={60} />
      )
    ) : null;

  return (
    <div className="topbar-container">
      <div className="menu-btn-div">{renderContent}</div>
      <div className="logo-container">
        <div className="logo-wrapper">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img src={Logo} alt="" className="logo-img" />
          </Link>
        </div>
      </div>
      <img
        src={FilterIcon}
        alt=""
        className={`filter-img ${
          isElementHidden === "hide" ? "hide-class" : ""
        }`}
        onClick={toggleFilter}
      />
      {toggleFilterModal && (
        <div className="filter-modal">
          <div className="filter-overlay" onClick={toggleFilter}></div>
          <div className="modal-content">
            <div className="button-container">
              <FaWindowClose
                size={"35px"}
                className="close-filter-modal-btn"
                onClick={toggleFilter}
              />
              <span className="filter-span">Available at</span>
              <div className="filter-time-div">
                <select
                  className="time-picker-from-filter"
                  name="available_from"
                  value={selectedFilters.available_from}
                  onChange={(e) => {
                    handleSelect(e.target.value, "available_from");
                  }}
                >
                  <optgroup
                    label="00-24 Hours"
                    className="time-picker-from-optgrop-filter"
                  >
                    <option value="00:00">00:00</option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>
                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>
                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                  </optgroup>
                </select>

                <select
                  className="time-picker-to-filter"
                  name="available_to"
                  value={selectedFilters.available_to}
                  onChange={(e) => handleSelect(e.target.value, "available_to")}
                >
                  <optgroup
                    label="00-24 Hours"
                    className="time-picker-to-optgrop-filter"
                  >
                    <option value="00:00">00:00</option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>
                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>
                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                  </optgroup>
                </select>
              </div>
              <span className="filter-span">Space city</span>
              <Select
                placeholder={
                  selectedFilters.city ? selectedFilters.city : "Select City"
                }
                name="city"
                onChange={(value) => handleSelect(value, "city")}
                className="city-selector"
                size="large"
                showSearch
                options={formatData("cities")}
              />
              <span className="filter-span">Space type</span>
              <Select
                placeholder={
                  selectedFilters.type ? selectedFilters.type : "Select Type"
                }
                className="type-selector"
                onChange={(value) => handleSelect(value, "type")}
                size="large"
                options={formatData("types", true)}
              />
              <div className="filets-btn-container">
                <button
                  className="filter-btn-reset"
                  onClick={() => handleResetFilters()}
                >
                  Reset
                </button>
                <button
                  className="filter-btn-accept"
                  onClick={() => handleApplyFilter()}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <SideMenu debt={debt} openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </div>
  );
}
