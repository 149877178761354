import React, { useState } from "react";
import "./spacePage.css";
import Topbar from "../../components/topbar/Topbar";
import SimpleImageSlider from "react-simple-image-slider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSpacesById } from "../../features/backendRoutes/spaceSlice";
import { useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import icon from "../../assets/img/map_pin.png";
import L from "leaflet";
import moment from "moment";
import { toast } from "react-toastify";
import { addBooking } from "../../features/backendRoutes/bookingSlice";
import { DatePicker } from "antd";
import Cookies from "universal-cookie";
import { getBookingAvailabilty } from "../../features/backendRoutes/bookingSlice";
import { clearAvailabilty } from "../../features/backendRoutes/bookingSlice";

export default function SpacePage() {
  const [hours, setHours] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleSpace } = useSelector((state) => state.space);
  const isAvailable = useSelector((state) => state.booking.bookingAvailabilty);
  const isChecked = useSelector((state) => state.booking.isChecked);
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    dispatch(getSpacesById(id));
    return () => {
      dispatch(clearAvailabilty());
    };
  }, [dispatch, id]);

  //checking the width of the window
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let cName = "space-page-right-side";
  if (width < 1311) {
    cName = "space-page-book-left-side";
  }

  function handleClick() {
    navigate(-1);
  }

  const timeFromSpace = singleSpace ? singleSpace.available_from : null;
  const timeToSpace = singleSpace ? singleSpace.available_to : null;

  const DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
  });

  const totalCost = () => {
    if (singleSpace) {
      const cost = singleSpace.price * hours;
      return cost.toFixed(2);
    }
  };
  const formatName = (o) => {
    const formattedWord = o.replace(/_/g, " ");
    return formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1);
  };

  const onChangeRange = (dates, dateStrings) => {
    if (dates) {
      setDateFrom(
        moment(dateStrings[0], "DD/MM/YYYY HH").format("YYYY-MM-DDTHH:mmZ")
      );
      setDateTo(
        moment(dateStrings[1], "DD/MM/YYYY HH").format("YYYY-MM-DDTHH:mmZ")
      );
      setDateRange(dates);
      let date_from = new Date(
        moment(dateStrings[0], "DD.MM.YYYY HH").format("YYYY, MM, DD, HH:00")
      );
      let date_to = new Date(
        moment(dateStrings[1], "DD.MM.YYYY HH").format("YYYY, MM, DD, HH:00")
      );
      function diffDates(date_to, date_from) {
        return (date_from - date_to) / 1000 / 3600;
      }
      setHours(diffDates(date_from, date_to));
    } else {
      setHours(0);
    }
  };

  useEffect(() => {
    if (isChecked) {
      if (isAvailable && isAvailable.is_available) {
        const resp = dispatch(
          addBooking({
            space_id: id,
            datetime_from: dateFrom,
            datetime_to: dateTo,
          })
        );
        resp.then((p) => {
          if (p.meta.requestStatus === "fulfilled") {
            toast.success("Successfully booked the space", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/");
          }
        });
      } else {
        toast.warning("The space is not available during this period", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, [isAvailable.is_available]);

  const handleBookSpace = async (e) => {
    e.preventDefault();
    if (token) {
      if (dateRange.length === 0) {
        toast.warn("Please enter correct data!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await dispatch(
          getBookingAvailabilty({
            space_id: id,
            datetime_from: dateFrom,
            datetime_to: dateTo,
          })
        );
      }
    } else {
      toast.warning("You must login in order to book a space!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <Topbar status={"hide"} />
      {singleSpace ? (
        <div className="space-page-container">
          <div className="space-page-left-side">
            <div className="space-page-header">
              <div className="space-page-back-div">
                <IoIosArrowBack
                  className="space-page-back-icon"
                  onClick={handleClick}
                />
                <span className="space-page-back-span" onClick={handleClick}>
                  Back
                </span>
              </div>
            </div>
            <div className="space-page-content-div">
              <div className="space-page-space-name-div">
                <h2 className="space-page-h2">{singleSpace.name}</h2>
                <h4 className="space-pageh4">{formatName(singleSpace.type)}</h4>
              </div>
              <div className="image-slider-container">
                <SimpleImageSlider
                  width={"100%"}
                  height={"100%"}
                  images={singleSpace?.image_urls?.map((url) => ({ url }))}
                  showBullets={true}
                  showNavs={true}
                  autoPlay={true}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              {width < 1311 && (
                <div className="space-page-booking-div">
                  <div className="space-page-availabilty-div">
                    <h3 className="space-page-h3">Availability</h3>
                    <span className="space-page-availabilty-span">
                      {timeFromSpace} - {timeToSpace}
                    </span>
                  </div>
                  <div className="space-page-price-div">
                    <span className="space-page-price-span">
                      €{singleSpace.price}
                    </span>
                    <span className="space-page-per-hour-span">per hour</span>
                  </div>
                  <div className="space-page-date-picker-div">
                    <span className="space-page-booking-period-span">
                      Select booking period
                    </span>
                    <RangePicker
                      size="large"
                      className="chechkout-date"
                      format="DD/MM/YYYY HH"
                      showTime={{ format: "HH" }}
                      disabledDate={(current) => {
                        let fromDate = moment(
                          singleSpace.available_from
                        ).format("YYYY-MM-DD");
                        let toDate = moment(singleSpace.available_to).format(
                          "YYYY-MM-DD"
                        );
                        if (current < fromDate) {
                          return (
                            (current &&
                              current < moment(fromDate, "YYYY-MM-DD")) ||
                            current >
                              moment(toDate, "YYYY-MM-DD").add(1, "days")
                          );
                        } else {
                          return (
                            moment().add(-1, "days") >= current ||
                            (current &&
                              current < moment(fromDate, "YYYY-MM-DD")) ||
                            current >
                              moment(toDate, "YYYY-MM-DD").add(1, "days")
                          );
                        }
                      }}
                      onChange={onChangeRange}
                      required
                    />
                  </div>
                  <div className="space-page-cost-div">
                    <div className="space-page-cost-wrapper">
                      <h3 className="space-page-h3">Total cost</h3>
                      <span className="space-page-total-cost-span">
                        {totalCost()}€
                      </span>
                    </div>
                    <span className="space-page-cost-span">
                      {singleSpace.price}€ x {hours} hours
                    </span>
                  </div>
                  <div className="book-btn-div">
                    <button
                      onClick={handleBookSpace}
                      className="space-page-book-btn"
                    >
                      Reserve
                    </button>
                  </div>
                </div>
              )}
              <div className="space-page-description-div">
                <h3 className="space-page-h3">Description</h3>
                <p className="space-page-desc-paragraph">
                  {singleSpace.detailed_description}
                </p>
              </div>
              <div className="space-page-address-div">
                <h3 className="space-page-h3">Address</h3>
                <span className="space-page-address-span">
                  {singleSpace.address},{singleSpace.city} {singleSpace.country}
                </span>
              </div>
            </div>
            <div className="space-page-map-container">
              <MapContainer
                className="checkout-map"
                center={[singleSpace.lat, singleSpace.lng]}
                zoom={13}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  key={singleSpace.id}
                  position={[singleSpace.lat, singleSpace.lng]}
                  icon={DefaultIcon}
                >
                  <Popup offset={[0, -30]}>{singleSpace.name}</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
          <div className={cName}>
            <div className="space-page-availabilty-div">
              <h3 className="space-page-h3">Availability</h3>
              <span className="space-page-availabilty-span">
                {timeFromSpace} - {timeToSpace}
              </span>
            </div>
            <div className="space-page-price-div">
              <span className="space-page-price-span">
                €{singleSpace.price}
              </span>
              <span className="space-page-per-hour-span">per hour</span>
            </div>
            <div className="space-page-date-picker-div">
              <span className="space-page-booking-period-span">
                Select booking period
              </span>
              <RangePicker
                size="large"
                className="chechkout-date"
                format="DD/MM/YYYY HH"
                showTime={{ format: "HH" }}
                disabledDate={(current) => {
                  let fromDate = moment(singleSpace.available_from).format(
                    "YYYY-MM-DD"
                  );
                  let toDate = moment(singleSpace.available_to).format(
                    "YYYY-MM-DD"
                  );
                  if (current < fromDate) {
                    return (
                      (current && current < moment(fromDate, "YYYY-MM-DD")) ||
                      current > moment(toDate, "YYYY-MM-DD").add(1, "days")
                    );
                  } else {
                    return (
                      moment().add(-1, "days") >= current ||
                      (current && current < moment(fromDate, "YYYY-MM-DD")) ||
                      current > moment(toDate, "YYYY-MM-DD").add(1, "days")
                    );
                  }
                }}
                onChange={onChangeRange}
                required
              />
            </div>
            <div className="space-page-cost-div">
              <div className="space-page-cost-wrapper">
                <h3 className="space-page-h3">Total cost</h3>
                <span className="space-page-total-cost-span">
                  {totalCost()}€
                </span>
              </div>
              <span className="space-page-cost-span">
                {singleSpace.price}€ x {hours} hours
              </span>
            </div>
            <div>
              <button onClick={handleBookSpace} className="space-page-book-btn">
                Reserve
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
