import React, { useState } from "react";
import "./pendingBookingsMobile.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBookingStatus } from "../../features/backendRoutes/landlordSlice";
import { updateBookingStatusTenant } from "../../features/backendRoutes/tenantSlice";

const PendingBookingsMobile = ({
  space,
  cancelOption,
  completeBtn,
  userTimezone,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formatDate = (date, userTimezone) => {
    // Adjust the date to the user's time zone
    const adjustedDate = new Date(
      date.toLocaleString("en-US", { timeZone: userTimezone })
    );

    const day = adjustedDate.getDate();
    const month = adjustedDate.getMonth() + 1;
    const year = adjustedDate.getFullYear();
    const hours = adjustedDate.getHours();
    const minutes = adjustedDate.getMinutes();

    return `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year} ${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  };

  const datetimeFrom = new Date(space.datetime_from);
  const formattedDatetimeFrom = formatDate(datetimeFrom);

  const datetimeTo = new Date(space.datetime_to);
  const formattedDatetimeTo = formatDate(datetimeTo);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = () => {
    navigate("/space/" + space.space.id);
  };

  const handleCancelBooking = () => {
    if (cancelOption === "tenant") {
      dispatch(
        updateBookingStatusTenant({ id: space.id, is_completed: false })
      );
    } else if (cancelOption === "landlord") {
      dispatch(updateBookingStatus({ id: space.id, is_completed: false }));
    }
  };

  const handleConfirmBooking = () => {
    dispatch(updateBookingStatus({ id: space.id, is_completed: true }));
  };

  return (
    <div className="pbm-main-div">
      <div className="pbm-headers-div">
        <span className="pbm-space-name" onClick={handleNavigate}>
          {space.space.name}
        </span>
        <div style={{ display: "flex", marginTop: "15px" }}>
          <div style={{ marginRight: "25px" }}>
            <span className="pbm-booking-headers">Booking time</span>
            <div className="pbm-booking-from-div">
              <span>from </span>
              <span>{formattedDatetimeFrom}</span>
            </div>
            <div>
              <span>to</span>
              <span className="pbm-to-date"> {formattedDatetimeTo}</span>
            </div>
          </div>
          <div className="pbm-address-div">
            <span className="pbm-address-headers">Address</span>
            <span className="pbm-address-span">
              {space.space.address} {space.space.city}
            </span>
          </div>
        </div>
      </div>
      <div className="pbm-wrapper">
        <div className="pbm-total-cost-div">
          <span className="pbm-price-headers">Status</span>
          <span>{space.status}</span>
        </div>
        <div className="pbm-total-cost-div">
          <span className="pbm-price-headers">Total price</span>
          <span>{space.cost}$</span>
        </div>
      </div>
      <div className="pbm-button-div">
        {completeBtn && (
          <button
            className="pbm-complete-btn"
            onClick={() => handleConfirmBooking()}
          >
            Approve
          </button>
        )}
        <button
          className="pbm-cancel-btn"
          onClick={() => handleCancelBooking()}
        >
          Cancel
        </button>
        <button onClick={handleOpen} className="pbm-view-btn">
          Contact
          {isOpen ? (
            <FiChevronUp size={25} className="pbm-icon-up" />
          ) : (
            <FiChevronDown size={25} className="pbm-icon-down" />
          )}
        </button>
      </div>
      {isOpen && (
        <div>
          <div className="pbm-contant-wrapper">
            <div className="pbm-name-div">
              <span className="pbm-name-span">Name</span>
              <span>
                {space.contact.first_name} {space.contact.last_name}
              </span>
            </div>
            <div className="pbm-contact-div">
              <span className="pbm-contact-span">Contact</span>
              <span>{space.contact.email}</span>
            </div>
          </div>
          <div className="pbm-phone-div">
            <span className="pbm-phone-span">Phone number</span>
            <span>+{space.contact.phone_number}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingBookingsMobile;
